'use client'

import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { validate2FA } from '../../providers/ApiProvider';

export default function Verified2FA({uuid,setStep,QR} : {uuid:string,setStep:any, QR:string}) {
    const AuthInputRef = useRef<AuthCodeRef>(null);
    const [qrcode,setQR] = useState<string>(`${QR}`)
    const [result, setResult] = useState<string>('');
    const handleOnChange = (res: string) => {
        setResult(res);
    };
    const notify = () => toast.error("Codigo invalido");

  return (
    <>
       <div className='flex flex-col justify-center items-center'>
            <h1 className='font-semibold text-gray-800 text-3xl mb-2 text-center'>Autentificação em 2 Etapas (2FA)</h1>
            <h2 className='text-sm text-gray-400 text-center'>Use um aplicativo de autentificação e escanei o QRCode abaixo:</h2>
        </div>
        <div className="flex flex-col items-center justify-center z-0 w-full mb-6 group">
            <img src={qrcode} alt=""/>
            <h2 className='font-semibold mb-2'>Digite o codigo</h2>
            <AuthCode ref={AuthInputRef} inputClassName="md:w-16 md:h-20 h-10 w-10 text-center px-3 rounded-xl md:text-4xl shadow py-2 border" length={6} containerClassName="flex flex-row gap-2 justify-center items-center" allowedCharacters='numeric' placeholder="0" onChange={handleOnChange} />
        </div>
        <button type="submit" onClick={async () => {
            if(result.length === 6){
                const resp = await validate2FA(uuid,result)
                if(resp !== "" && resp !== 1)
                    setStep(3)
                    return
            }else{
                notify()
                return
            }
        }} className="text-white mb-5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">Validate</button>
    </>
  )
}
