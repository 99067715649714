
import {IoArrowDownCircleSharp, IoArrowUpCircleSharp} from 'react-icons/io5'
import Card from '../../components/Card'
import Brazil from "@svg-maps/brazil";
import { SVGMap } from "react-svg-map";
import { Chart } from "react-google-charts";

import "react-svg-map/lib/index.css";
import { useAuth } from '../../providers/AuthProvider';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { getReport, getUnits } from '../../providers/ApiProvider';
import moment from 'moment';

export default function Reports() {
    const user = JSON.parse(Cookies.get('jwt') || "{}");
    const [unit,setUnit] = useState<any>(null);
    const [units,setUnits] = useState<any>([]);
    const [loading,setLoading] = useState<boolean>(false);
    const [date,setDate] = useState<string>('');
    const [data,setData] = useState<any>(null);

    const getNext12MonthsStartInOct2023UsingMoment = () => {
        const months = [];
        const date = moment('2023-10-01');
        for (let i = 0; i < 12; i++) {
            date.add(1, 'month')
            const monthInPortugueseFromStart = date.toDate().toLocaleString('pt-BR', { month: 'long' }).toUpperCase();
            months.push({
                text: `${monthInPortugueseFromStart}/${moment(date).format('YY')}`,
                value: `${moment(date).format('YY')}-${moment(date).format('MM')}`
            });
        }
        return months;
    }

    useEffect(() => {
        if (user.type === "admin"){
            setLoading(true);
            getUnits().then((response) => {
                setUnits(response);
                setLoading(false);
            })
        }
    },[])

    const [graph,setGraph] = useState<any>([]);

    useEffect(() => {
        if (unit !== null && date !== ''){
            setLoading(true);
            getReport(unit,date).then((response) => {
                setData(response);
                signedFugulins(response.unit)
                groupByDaysFugulin(response.unit)
                setLoading(false);
            })
        }
    },[unit,date])

    const signedFugulins = (data:any) => {
        const result:any = [];
        let score = 0;
        for (const item of data.sectors) {
            for(const fugulin of item.beds){
                score+= fugulin.fugulin.length
            }
        }
        return score*12;
    }

    const leitos = (data:any) => {
        const result:any = [];
        let leitos = 0;
        let livres = 0
        for (const item of data.sectors) {
            for(const fugulin of item.beds){
                leitos++;
                if (fugulin.isFree){
                    livres++;
                }
            }
        }
        return {leitos,livres};
    }

    const rangeAllDaysInMonth = (month:any) => {
        const days = [];
        const date = new Date();
        date.setMonth(month.split('-')[1]-1);
        date.setFullYear(month.split('-')[0]);
        const lastDay = new Date().getDate();
        for (let i = 1; i <= lastDay; i++) {
            if (i<10)
            days.push(`0${i}/${month.split('-')[1] < 10 ? 0+month.split('-')[1] : month.split('-')[1]}/${date.getFullYear()}`);
            else 
            days.push(`${i}/${month.split('-')[1] < 10 ? 0+month.split('-')[1] : month.split('-')[1] }/${date.getFullYear()}`);
        }
        return days;
    }

    const groupByDaysFugulin = (data:any) => {
        const result:any = [];
        for (const item of data.sectors) {
            for(const bed of item.beds){
                for(const fugulin of bed.fugulin){
                    result.push(fugulin)
                }
            }
        }
    

        const days = rangeAllDaysInMonth(date);
        const result2:any = [
            ["Dia", "Horas Disponiveis","Fugulin médio"],
        ];
        for (const day of days) {
            const dayFugulin = result.filter((fugulin:any) => {
                return moment(fugulin.createdAt).format("DD/MM/YYYY")===day
            });
            //average score reduce
            const average = dayFugulin.reduce((a:any, b:any) => a + b.score, 0) / dayFugulin.length || 1;
            result2.push([
                day.slice(0,5),
                (Number(data?.diurno)+Number(data?.diurno))/days.length,
                average
            ]);
        }
        setGraph(result2)
    }

    return (
    <div className='flex flex-col w-full'>
    {loading &&
        <div className='flex flex-col w-full items-center justify-center mt-7'>
            <h1 className='text-3xl animate-bounce'>Carregando...</h1>
        </div>
    }
    {!loading &&
    <div className='flex flex-col w-full'>
        <div className='flex flex-row items-center justify-between'>
            <h1 className='font-semibold text-2xl'>Relatórios</h1>
        </div>
        <div className='flex flex-row w-full gap-3'>
            <div className='flex flex-col justify-center items-center w-full md:w-6/12'>
                <select onChange={(e:any)=> setUnit(e.target.value)} value={unit} className='w-full p-2 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-cyan-500 to-blue-500'>
                    <option>Selecione uma unidade</option>
                    {units && units.map((unit:any) => (
                        <option value={unit._id}>{unit.name}</option>
                    ))}
                </select>
            </div>
            <div className='flex flex-col justify-center items-center w-full md:w-6/12'>
                <select onChange={(e:any)=> setDate(e.target.value)} value={date} className='w-full p-2 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-cyan-500 to-blue-500'>
                    <option>Selecione o mês</option>
                    {getNext12MonthsStartInOct2023UsingMoment().map((month) => (
                        <option value={month.value}>{month.text}</option>
                    ))}
                </select>
            </div>
        </div>
    </div>
    }
    {data !== null &&
        <>
            {user.type === "admin" &&
                <div className="flex flex-col w-full mt-4 mb-8">        
                    <div className="flex items-center justify-between flex-row">
                        <div>
                            <h1 className="font-semibold text-2xl">Report {data.unit?.name} {date}</h1>
                            <p className="text-gray-400">Abaixo acompanhe todo os processos</p>
                        </div>
                        <div className="flex flex-row items-center gap-6">
                            <div className="flex flex-col items-end">
                                <p className="text-xs text-[#78829a]">Total Restante de horas da Unidade</p>
                                <h1 className="font-semibold text-xl">{Number(data?.unit?.diurno)+Number(data?.unit?.diurno)-Number(signedFugulins(data?.unit))} horas</h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full lg:flex-row mt-6 gap-6">
                        <div className="flex flex-col w-full p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-cyan-500 to-blue-500">
                            <p className="roboto text-xs font-semibold mb-4">Horas preenchidas</p>
                            <div>
                                <div className="font-bold roboto text-3xl">{Number(signedFugulins(data?.unit))} horas</div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#f93a5a] to-[#f7778c]">
                            <p className="roboto text-xs font-semibold mb-4">Leitos vazios</p>
                            <div>
                                <div className="font-bold roboto text-3xl">{leitos(data?.unit)?.livres}</div>
                                <div className="text-xs flex flex-row items-center justify-between">
                                    <p>Total de leitos</p>
                                    <p className='flex flex-row justify-center items-center gap-1'>
                                        {leitos(data?.unit)?.leitos}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#48d6a8] to-green-600">
                            <p className="roboto text-xs font-semibold mb-4">Colaboradores</p>
                            <div>
                                <div className="font-bold roboto text-3xl">{data?.unit?.employees.length}</div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l to-[#f76a2d] from-[#efa65f]">
                            <p className="roboto text-xs font-semibold mb-4">Pacientes</p>
                            <div>
                                <div className="font-bold roboto text-3xl">{data?.patients.length || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 lg:flex-row  w-full mt-6">
                        <div className='w-full lg:w-full flex flex-row items-center justify-between'>
                            <Card title='Horas de assistencia da enfermagem paciente' desc='Horas / Fugulin '>
                            <Chart
                                    chartType="Line"
                                    width="100%"
                                    height="400px"
                                    data={graph}
                                    options={{
                                    }}
                                />                    
                            </Card>
                        </div>
                        
                    </div>
                </div>
            }
        </>
    }
    </div>
    )

}