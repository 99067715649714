export default function Card({children,title,desc, classes}:{children:React.ReactNode,title?:string,desc?:string, classes ?: string}) {


    return (
        <div className={`${classes !== undefined  ? classes :'w-full p-4'} bg-white shadow-sm rounded-md`}>
            {title !== undefined && <h1 className="roboto text-[13px] uppercase font-bold">{title}</h1>}
            {desc !== undefined && <h2 className="roboto text-xs font-light">{desc}</h2>}
            <div className="flex flex-col py-3">
                {children}
            </div>
        </div>
    )
}