'use client'

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import Code from '../start/code';
import { useCookies } from 'react-cookie';



export default function Logout() {
    
    const [loading,setLoading] = useState<boolean>(true);
    const [cookies, setCookie, removeCookie] = useCookies(['jwt','myUser']); // Use react-cookie hooks
    const navigate = useNavigate();

    useEffect(() => {
        removeCookie("jwt", { path: '/',secure:true }); // Set the JWT to cookies
        removeCookie("myUser", { path: '/',secure:true }); // Set the JWT to cookies
        setLoading(false);
        navigate('/login');
    }, []);

  return (
    <>  
        {loading &&
            <div className='fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center'>
                <div className='bg-white rounded-lg p-8'>
                    <h1 className='text-2xl font-semibold text-gray-800'>Carregando...</h1>
                </div>
            </div>
        }                     
    </>
  )
}
