'use client'

import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../providers/AuthProvider';

export default function Code({checkCode, uuid, token,setStep} : {checkCode?:any, uuid?:string, token?:string, setStep?:any}) {
    const AuthInputRef = useRef<AuthCodeRef>(null);    
    const { login } = useAuth();
    const [result, setResult] = useState<string>('');
    const handleOnChange = (res: string) => {
        setResult(res);
    };
  return (
    <>
       <div className='flex flex-col justify-center items-center'>
            <h1 className='font-semibold text-gray-800 text-3xl mb-2 text-center'>Verificação de Token</h1>
            <h2 className='text-sm text-gray-400'>Digite abaixo o codigo de 6 digitos recebidos</h2>
        </div>
        <div className="flex flex-col items-center justify-center z-0 w-full mb-6 group">
            <h2 className='font-semibold mb-2'>Digite o codigo</h2>
            <AuthCode ref={AuthInputRef} inputClassName="md:w-16 md:h-20 h-10 w-10 text-center px-3 rounded-xl md:text-4xl shadow py-2 border" length={6} containerClassName="flex flex-row gap-2 justify-center items-center" allowedCharacters='numeric' placeholder="0" onChange={handleOnChange} />
        </div>
        <button type="submit" onClick={() => {
            if(result.length === 6){
                checkCode(result)
            } else{
                toast.error('O codigo precisa ter 6 digitos!')
            }
        }} className="text-white mb-5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">Continue</button>
    </>
  )
}
