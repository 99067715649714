import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { createFugulin, getBeds } from "../../providers/ApiProvider";



interface IFormInputs {
    name: string
    gender: string
    age: number
    risk: number
    
  }

export default function FormFugulin() {
    let { uuid, setor, edit } = useParams();

    const [estado,setEstado] = React.useState<number>(0)    
    const [oxigenacao,setOxigencao] = React.useState<number>(0)
    const [sinais,setSinais] = React.useState<number>(0)
    const [motilidade,setMotilidade] = React.useState<number>(0)
    const [deambulacao,setDeambulacao] = React.useState<number>(0)
    const [alimentacao,setAlimentacao] = React.useState<number>(0)
    const [cuidado,setCuidado] = React.useState<number>(0)
    const [eliminacao,setElimincacao] = React.useState<number>(0)
    const [terapeutica,setTerapeutica] = React.useState<number>(0)

    const [loading, setLoading] = React.useState<boolean>(edit !== undefined ? true : false)
    const Schema = yup.object().shape({
        name: yup.string()
        .label('Nome')
        .required("Digite um nome válido"),
        gender: yup.string()
        .label('Gênero')
        .required("Digite um gênero válido"),
        age: yup.number()
        .label('Idade')
        .min(0, "Digite uma idade válida (0 a 110)")
        .max(110, "Digite uma idade válida (0 a 110)")
        .required("Digite uma idade válida"),
        risk: yup.number()
        .label('Idade')
        .min(0, "Digite uma idade válida (0 a 110)")
        .max(110, "Digite uma idade válida (0 a 110)")
        .required("Digite uma idade válida"),
        
    });
    const [obj, setObj] = React.useState<any>(null)
    const navigate = useNavigate();
      const submitForm = async () => {
        if (estado === 0){ toast.error("Preencha o estado mental"); return; }
        if (oxigenacao === 0){ toast.error("Preencha a oxigenação"); return;}
        if (sinais === 0){ toast.error("Preencha os sinais vitais"); return;}
        if (motilidade === 0){ toast.error("Preencha a motilidade"); return; }
        if (deambulacao === 0){toast.error("Preencha a deambulação");return; }
        if (alimentacao === 0){toast.error("Preencha a alimentação");return; }
        if (cuidado === 0){toast.error("Preencha o cuidado");return; }
        if (eliminacao === 0){toast.error("Preencha a eliminação");return; }
        if (terapeutica === 0){ toast.error("Preencha a terapeutica"); return; }
        setLoading(true);
        const data = { 
            estado_mental: estado,
            oxigenacao: oxigenacao,
            sinais_vitais: sinais,
            motilidade: motilidade,
            deambulacao: deambulacao,
            alimentacao: alimentacao,
            cuidado: cuidado,
            eliminacao: eliminacao,
            terapeutica: terapeutica,
            bed: uuid,
            patient: obj.obj?.patient?.uuid,
        }
        const resp = await createFugulin(data,uuid!)        
        if (resp !== null){
            toast.success("Fugulin cadastrado com sucesso!")
            //delay            
            setEstado(0)
            setOxigencao(0)
            setSinais(0)
            setMotilidade(0)
            setDeambulacao(0)
            setAlimentacao(0)
            setCuidado(0)
            setElimincacao(0)
            setTerapeutica(0)
            await new Promise(resolve => setTimeout(resolve, 2000));
            window.history.back();
        }
        setLoading(false);
        
      }
      
      useEffect(() => {
        if (uuid !== undefined && setor !== undefined){
            setLoading(true);
            const fetchData = async () => {
                const response = await getBeds(setor!, uuid)
                setLoading(false);
                if (response !== null) {
                    setObj(response)
                }
            }
            fetchData()
        }
    }, [])

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-row justify-between items-center">
                <Breadcrumb title="Prenchimento do status do paciente" links={[

                ]} />
            </div>
            <div className="flex md:flex-row flex-col justify-between items-center gap-4">            
                <div className="flex flex-col w-full">
                    <Card classes="p-4 pt-2">
                        <div className="flex flex-col gap-4">
                           {loading && (
                               <div className="flex flex-col items-center justify-center">  
                                    <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-green-500">Carregando...</span>
                                </div>
                            )}
                            {!loading && (
                                <div className='w-full mb-6'>
                                <h1 className="text-2xl font-bold text-gray-900">{obj && obj.obj?.patient?.name} de {obj && obj.obj?.patient?.age} ano(s)</h1>
                                <h1> {obj && obj.obj?.name} | {obj && obj.sector?.name}</h1>
                                <h1 className="font-bold text-green-700">Unidade: {obj && obj.sector?.unit?.name}</h1>
                                <h2 className="pb-2 text-xs">{obj && obj.sector?.unit?.address}, {obj && obj.sector?.unit?.city}-{obj && obj.sector?.unit?.state}</h2>
                                <h2 className="mb-6 text-center font-bold underline underline-offset-2">Escala de Fugulin</h2>
                                <div className="flex flex-col w-full mb-7">
                                    <div className="flex flex-row bg-slate-300">
                                        <div className="w-2/12 border border-slate-600 p-3">
                                            Área de Cuidado
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="text-center border border-slate-600 p-3">Gradaçao da complexidade assistencial</div>
                                            <div className="flex w-full justify-between flex-row">
                                                <div className="text-center w-3/12 border border-slate-600 p-4">4</div>
                                                <div className="text-center w-3/12 border border-slate-600 p-4">3</div>
                                                <div className="text-center w-3/12 border border-slate-600 p-4">2</div>
                                                <div className="text-center w-3/12 border border-slate-600 p-4">1</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12  bg-slate-300 border border-slate-600 p-3">
                                            Estado Mental
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setEstado(4)} className={`${estado===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Inconsciente</div>
                                                <div onClick={() => setEstado(3)} className={`${estado===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Períodos de inconsciência</div>
                                                <div onClick={() => setEstado(2)} className={`${estado===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Periodos de desorientação no tempo e no espaço</div>
                                                <div onClick={() => setEstado(1)} className={`${estado===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Orientação no tempo e no espaço</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Oxigenação
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setOxigencao(4)} className={`${oxigenacao===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Ventilação mecânica (uso do ventilador a pressão ou a volume)</div>
                                                <div onClick={() => setOxigencao(3)} className={`${oxigenacao===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Uso continuo de máscara ou cateter de oxigênio</div>
                                                <div onClick={() => setOxigencao(2)} className={`${oxigenacao===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Uso intermitente de mascara ou cateter de oxigênio</div>
                                                <div onClick={() => setOxigencao(1)} className={`${oxigenacao===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Não depende de oxigênio</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Sinais Vitais
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setSinais(4)} className={`${sinais===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Controle em intervalos menores ou iguais a 2 horas</div>
                                                <div onClick={() => setSinais(3)} className={`${sinais===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Controle em intervalos de 4 horas</div>
                                                <div onClick={() => setSinais(2)} className={`${sinais===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Controle em intervalos de 6 horas</div>
                                                <div onClick={() => setSinais(1)} className={`${sinais===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Controle de rotina (8 horas)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Motilidade
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setMotilidade(4)} className={`${motilidade===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Incapaz de movimentar qualquer segmento corporal Mudança de decúbito e movimentação passiva programada e realizada pela enfermagem</div>
                                                <div onClick={() => setMotilidade(3)} className={`${motilidade===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Dificuldade para movimentar segmentos corporais Mudança de decúbito e movimentação passiva auxiliada pela enfermagem</div>
                                                <div onClick={() => setMotilidade(2)} className={`${motilidade===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Limitação de movimentos</div>
                                                <div onClick={() => setMotilidade(1)} className={`${motilidade===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Movimenta todos os segmentos corporais</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Deambulação
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setDeambulacao(4)} className={`${deambulacao===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Restrito ao leito</div>
                                                <div onClick={() => setDeambulacao(3)} className={`${deambulacao===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Locomoção através de cadeira de rodas</div>
                                                <div onClick={() => setDeambulacao(2)} className={`${deambulacao===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Necessita de auxilio para deambular</div>
                                                <div onClick={() => setDeambulacao(1)} className={`${deambulacao===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Ambulante</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Alimentação
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setAlimentacao(4)} className={`${alimentacao===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Atraves de cateter central</div>
                                                <div onClick={() => setAlimentacao(3)} className={`${alimentacao===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Através de sonda nasogastrica</div>
                                                <div onClick={() => setAlimentacao(2)} className={`${alimentacao===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Por boca com auxilio</div>
                                                <div onClick={() => setAlimentacao(1)} className={`${alimentacao===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Auto suficiente</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Cuidado corporal
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setCuidado(4)} className={`${cuidado===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Banho no leito, higiene oral realizada pela enfermagem</div>
                                                <div onClick={() => setCuidado(3)} className={`${cuidado===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Banho no chuveiro, higiene oral realizada pela enfermagem</div>
                                                <div onClick={() => setCuidado(2)} className={`${cuidado===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Auxilio no banho de chuveiro e/ou na higiene oral</div>
                                                <div onClick={() => setCuidado(1)} className={`${cuidado===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Auto suficiente</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Eliminação
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setElimincacao(4)} className={`${eliminacao===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Evacuação no leito e uso de sonda vesical para controle de diurese</div>
                                                <div onClick={() => setElimincacao(3)} className={`${eliminacao===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Uso de comadre ou eliminações no leito</div>
                                                <div onClick={() => setElimincacao(2)} className={`${eliminacao===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Uso de vaso sanitário com auxilio</div>
                                                <div onClick={() => setElimincacao(1)} className={`${eliminacao===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Uso de vaso sanitário com auxilio</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="w-2/12 border bg-slate-300 border-slate-600 p-3">
                                        Terapêutica
                                        </div>
                                        <div className="w-10/12 flex flex-col">
                                            <div className="flex w-full justify-between flex-row">
                                                <div onClick={() => setTerapeutica(4)} className={`${terapeutica===4 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>Uso de drogas vasoativas para manutenção de P.A.</div>
                                                <div onClick={() => setTerapeutica(3)} className={`${terapeutica===3 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>E.V. continua ou atraves de sonda nasogástrica</div>
                                                <div onClick={() => setTerapeutica(2)} className={`${terapeutica===2 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>E.V. intermitente</div>
                                                <div onClick={() => setTerapeutica(1)} className={`${terapeutica===1 ? 'bg-slate-700 font-bold text-white' : 'hover:bg-slate-100'} w-3/12 border cursor-pointer border-slate-600 p-4`}>I.M. ou V.O.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                    <button onClick={submitForm} className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                                        Salvar
                                    </button>
                                </div>   
                            )}

                            
                        </div>


                    </Card>
                </div>
            </div>
        </div>
    )
}