import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { createSector, createUnits, getSector, getUnits } from "../../providers/ApiProvider";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";



interface IFormInputs {
    name: string
  }

export default function FormSetor() {
    let { uuid, edit, setores } = useParams();
    const [loading, setLoading] = React.useState<boolean>(edit !== undefined ? true : false)
    const Schema = yup.object().shape({
        name: yup.string()
        .label('Nome')
        .required("Digite um nome válido"),
    });

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm<IFormInputs>({
        mode: "onTouched",
        resolver: yupResolver(Schema)
      });
    
      const submitForm = async (data:any) => {
        setLoading(true);
        let response;
        if (uuid !== undefined && edit !== undefined){
            response = await createSector(data, uuid,edit);
        } else if(uuid !== undefined) {
            response = await createSector(data,uuid);
        }
        setLoading(false);
        if (response !== null) {
            toast.success("Setor cadastrada com sucesso!");
            if (edit === undefined){
                setValue("name", "");
            }
        }
      }

    useEffect(() => {
        if (uuid !== undefined && edit !== undefined){
            const fetchData = async () => {
                const response = await getSector(uuid!, edit)
                setLoading(false);
                if (response !== null) {
                    Object.entries(response).forEach(
                        ([name, value]: any) => setValue(name, value, { shouldValidate: true }));
                }
            }
            fetchData()
        }
    }, [])

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-row justify-between items-center">
                <Breadcrumb title="Unidades" links={[
                    {title: "Home", link: "/", class: "text-gray-500"},
                    {title: "Unidades", link: "/unidades", class: "text-gray-500"},
                    {title: "Setores", link: `/unidades/${uuid}/setores`, class: "text-gray-500"},
                    {title: edit ? "Editar" : "Cadastro", link:  `/unidades/${uuid}/setores/create`, class: "text-green-500"},
                ]} />
            </div>
            <div className="flex md:flex-row flex-col justify-between items-center gap-4">            
                <div className="flex flex-col w-full">
                    <Card classes=" p-4">
                        <div className="flex flex-col gap-4">
                           {loading && (
                               <div className="flex flex-col items-center justify-center">  
                                    <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-green-500">Carregando...</span>
                                </div>
                            )}
                            {!loading && (
                                <form className='w-full my-6' autoComplete='off'  onSubmit={handleSubmit((data) => {console.log(data);submitForm(data)})}>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input autoComplete='off'  type="text" {...register('name')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nome do Setor</label>
                                        {errors.name && <p className="text-red-500 mb-4 text-xs italic">{errors.name.message?.toString()}</p>}
                                    </div>
                                    <button type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                                        Salvar
                                    </button>
                                </form>   
                            )}

                            
                        </div>


                    </Card>
                </div>
            </div>
        </div>
    )
}