import React, { Suspense, Fragment } from "react";
import {Routes,Route, Link, Navigate, defer} from "react-router-dom";
import { useAuth } from "./providers/AuthProvider";
import { AuthLayout } from "./Layout/AuthLayout";
import { Layout } from "./Layout/MainLayout";
import Login from "./pages/auth/login";
import FlowStart from "./pages/start/flow";
import { IconContext } from "react-icons";
import Dashboard from "./pages/dashboard";
import Units from "./pages/units";
import FormUnit from "./pages/units/form";
import FormUser from "./pages/users/form";
import Users from "./pages/users";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Setores from "./pages/setores";
import FormSetor from "./pages/setores/form";
import Leitos from "./pages/leitos";
import FormLeitos from "./pages/leitos/form";
import FormPatient from "./pages/leitos/paciente";
import FormFugulin from "./pages/leitos/fugulin";
import Logout from "./pages/auth/logout";
import ListPatient from "./pages/leitos/list_paciente";
import Reports from "./pages/reports";
import Fugulins from "./pages/fugulins";
import FormEditFugulin from "./pages/leitos/editfugulin";
import ResetPassword from "./pages/auth/reset";

const getUserData = () =>

  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      console.log(user)
      resolve(user);
    }, 3000)
  );
  
export default function App() {
  return (
      <IconContext.Provider value={{ className: "react-icons" }}>
        <ToastContainer />
        <Suspense fallback={<>LOADING</>}>
          <Routes>
            <Route path="/login" element={<Layout />}>
              <Route path="" index element={<Login />} />              
            </Route>
            <Route path="/logout" element={<Logout />} />
            <Route path="/activate-account" element={<Layout />}>
              <Route path=":uuid" element={<FlowStart />} />            
            </Route>
            <Route path="/reset-account" element={<Layout />}>
              <Route path=":uuid" element={<ResetPassword />} />            
            </Route>
            <Route path="/" loader={() => defer({ userPromise: getUserData() })} element={<AuthLayout />}>
              <Route path="" index element={<Dashboard/>} />
              <Route path="unidades" index element={<Units/>} />
              <Route path="dashboard" index element={<Reports/>} />
              <Route path="unidades/create" index element={<FormUnit/>} />
              <Route path="unidades/edit/:id" index element={<FormUnit/>} />
              <Route path="unidades/:uuid/setores" index element={<Setores/>} />
              <Route path="unidades/:uuid/setores/create" index element={<FormSetor/>} />
              <Route path="unidades/:uuid/pacientes" index element={<ListPatient/>} />
              <Route path="unidades/:uuid/pacientes/create" index element={<FormPatient/>} />
              <Route path="unidades/:uuid/setores/:edit/create" index element={<FormSetor/>} />
              <Route path="unidades/:uuid/setores/:edit/leitos" index element={<Leitos/>} />
              <Route path="unidades/:uuid/setores/:setores/leitos/create" index element={<FormLeitos/>} />
              <Route path="unidades/:uuid/setores/:setores/leitos/:edit" index element={<FormLeitos/>} />
              <Route path="/setor/:setor/leito/:uuid/paciente" index element={<FormPatient/>} />
              <Route path="setor/:setor/leito/:uuid/fugulin" index element={<FormFugulin/>} />
              <Route path="setor/:setor/leito/:uuid/fugulin/:id" index element={<FormEditFugulin/>} />
              <Route path="usuarios" index element={<Users/>} />
              <Route path="usuarios/create" index element={<FormUser/>} />
              <Route path="usuarios/edit/:id" index element={<FormUser/>} />              
              <Route path="fugulins" index element={<Fugulins/>} />
            </Route>
            
          <Route path="*" element={<NoMatch />} />
          </Routes>
        </Suspense>
      </IconContext.Provider>
  );
}

function NoMatch() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center">
        <h1 className="text-6xl">404</h1>
        <h2>Página não encontrada!</h2>
        <p className="bg-green-500 text-white px-4 py-2 rounded shadow-lg mt-10">
          <Link to="/">Clique aqui para voltar para a Home</Link>
        </p>
    </main>
  );
}
