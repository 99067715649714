import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { useNavigate, useParams } from "react-router-dom";
import { deleteLeito, deleteSetor, deleteUnits, getBeds, getPatients, getUnits, removePaciente } from "../../providers/ApiProvider";
import Cookies from "js-cookie";


export default function ListPatient() {

    const [obj, setObj] = React.useState<any>([])
    const [loading, setLoading] = React.useState<boolean>(true)
    const navigate = useNavigate();
    const {uuid,edit} = useParams()
    const user = JSON.parse(Cookies.get('jwt') || "{}");


    useEffect(() => {
            getPatients(uuid).then((res) => {
                setObj(res)
                setLoading(false)
            })
    }, [])

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-row justify-between items-center">
                <Breadcrumb title="Pacientes" links={[
                    {title: "Home", link: "/", class: "text-gray-500"},
                    {title: "Unidades", link: "/unidades", class: "text-gray-500"},
                    {title: "Pacientes", link: `#`, class: "text-green-500"},
                ]} />
                <button onClick={() => navigate(`/unidades/${uuid}/pacientes/create`)} className="flex flex-row items-center gap-2 bg-green-500 text-white px-4 py-2 rounded shadow-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                    <span>Adicionar</span>
                </button>
            </div>
            <div className="flex md:flex-row flex-col justify-between items-center gap-4">            
                <div className="flex flex-col w-full">
                    <Card classes="md:min-h-[70vh] p-4">
                        <div className="flex flex-col gap-4">
                           {loading && (
                               <div className="flex flex-col items-center justify-center">  
                                    <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-green-500">Carregando...</span>
                                </div>
                            )}
                            {!loading && (
                                <div className="relative overflow-x-auto sm:rounded-lg">
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Nome
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Gênero
                                                </th>
                                                <th scope="col" className="px-6 py-3  text-right">
                                                    Ações
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {obj && obj?.map((item: any, index: number) => (
                                                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                                    <th onClick={() => navigate(`/unidades/${uuid}/setores/${edit}/leitos/${item.uuid}`)} scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap cursor-pointer">
                                                        {item.name}
                                                    </th>
                                                    <td className="px-6 py-4 flex">
                                                            {(!item.isFree) && (item?.gender)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
                                        <span className="text-sm font-normal text-gray-500">Mostrando <span className="font-semibold text-gray-900">1-10</span> de <span className="font-semibold text-gray-900 ">1000</span></span>
                                        <ul className="inline-flex -space-x-px text-sm h-8">
                                            <li>
                                                <a href="#" className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Anterior</a>
                                            </li>
                                            <li>
                                                <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">1</a>
                                            </li>
                                            <li>
                                                <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Proximo</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>    
                            )}

                            
                        </div>


                    </Card>
                </div>
            </div>
        </div>
    )
}