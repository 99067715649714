
import {IoArrowDownCircleSharp, IoArrowUpCircleSharp} from 'react-icons/io5'
import Card from '../../components/Card'
import Brazil from "@svg-maps/brazil";
import { SVGMap } from "react-svg-map";
import { Chart } from "react-google-charts";

import "react-svg-map/lib/index.css";
import { useAuth } from '../../providers/AuthProvider';
import Cookies from 'js-cookie';

export default function Dashboard() {
    const user = JSON.parse(Cookies.get('jwt') || "{}"); 
    
    return (
    <>
    {user.type === "admin" &&
        <div className="flex flex-col w-full mt-4 mb-8">        
            <div className="flex items-center justify-between flex-row">
                <div>
                    <h1 className="font-semibold text-2xl">Seja bem vindo {user.name || 'Usuário'}</h1>
                    <p className="text-gray-400">Abaixo acompanhe todo os processos</p>
                </div>
            </div>
            <div className="flex flex-col w-full lg:flex-row mt-6 gap-6">
                <a href='/' className="flex flex-col w-full lg:w-6/12 p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-cyan-500 to-blue-500">
                    <div>
                        <div className="font-bold roboto text-3xl text-center">Home</div>
                        <div className="text-xs  text-center">
                            <p>Acessar página inicial</p>
                        </div>
                    </div>
                </a>
                <a href='/dashboard' className="flex flex-col w-full lg:w-6/12 p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#f93a5a] to-[#f7778c]">
                    <div>
                        <div className="font-bold  text-center roboto text-3xl">Informações</div>
                        <div className="text-xs  text-center">
                            <p>Veja os relátorios</p>
                        </div>
                    </div>
                </a>
                <a href='/unidades' className="flex flex-col w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#48d6a8] to-green-600">
                    <div>
                        <div className="font-bold roboto text-center text-3xl">Unidades</div>
                        <div className="text-xs  text-center">
                            <p>Unidades de atendimento</p>
                        </div>
                    </div>
                </a>
                <a href="/usuarios" className="flex flex-col  w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l to-[#f76a2d] from-[#efa65f]">
                    <div>
                        <div className="font-bold roboto text-center text-3xl">Gestores</div>
                        <div className="text-xs  text-center">
                            <p>Cadastro de gestores das unidades</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    }
    {user.type === "gestor" &&
        <div className="flex flex-col w-full mt-4 mb-8">        
            <div className="flex items-center justify-between flex-row">
                <div>
                    <h1 className="font-semibold text-2xl">Seja bem vindo {user.name || 'Usuário'}</h1>
                    <p className="text-gray-400">Abaixo acompanhe todo os processos</p>
                </div>
            </div>
            <div className="flex flex-col w-full lg:flex-row mt-6 gap-6">
                <a href='/' className="flex flex-col w-full lg:w-6/12 p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-cyan-500 to-blue-500">
                    <div>
                        <div className="font-bold roboto text-3xl text-center">Home</div>
                        <div className="text-xs  text-center">
                            <p>Acessar página inicial</p>
                        </div>
                    </div>
                </a>
                <a href='/dashboard' className="flex flex-col w-full lg:w-6/12 p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#f93a5a] to-[#f7778c]">
                    <div>
                        <div className="font-bold  text-center roboto text-3xl">Informações</div>
                        <div className="text-xs  text-center">
                            <p>Veja os relátorios</p>
                        </div>
                    </div>
                </a>
                <a href='/unidades' className="flex flex-col w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#48d6a8] to-green-600">
                    <div>
                        <div className="font-bold roboto text-center text-3xl">Unidades</div>
                        <div className="text-xs  text-center">
                            <p>Unidades de atendimento</p>
                        </div>
                    </div>
                </a>
                <a href="/usuarios" className="flex flex-col  w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l to-[#f76a2d] from-[#efa65f]">
                    <div>
                        <div className="font-bold roboto text-center text-3xl">Supervisores</div>
                        <div className="text-xs  text-center">
                            <p>Cadastro de supervisores das unidades</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    }
    {user.type === "supervisor" &&
       <div className="flex flex-col w-full mt-4 mb-8">        
       <div className="flex items-center justify-between flex-row">
           <div>
               <h1 className="font-semibold text-2xl">Seja bem vindo {user.name || 'Usuário'}</h1>
               <p className="text-gray-400">Abaixo acompanhe todo os processos</p>
           </div>
       </div>
       <div className="flex flex-col w-full lg:flex-row mt-6 gap-6">
           <a href='/' className="flex flex-col w-full lg:w-6/12 p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-cyan-500 to-blue-500">
               <div>
                   <div className="font-bold roboto text-3xl text-center">Home</div>
                   <div className="text-xs  text-center">
                       <p>Acessar página inicial</p>
                   </div>
               </div>
           </a>
           <a href={`/unidades/${user.unitObj}/setores/`} className="flex flex-col w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#48d6a8] to-green-600">
               <div>
                   <div className="font-bold roboto text-center text-3xl">Setores</div>
                   <div className="text-xs  text-center">
                       <p>Informações por setor da unidade</p>
                   </div>
               </div>
           </a>
           <a href="/usuarios" className="flex flex-col  w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l to-[#f76a2d] from-[#efa65f]">
               <div>
                   <div className="font-bold roboto text-center text-3xl">Colaboradores</div>
                   <div className="text-xs  text-center">
                       <p>Cadastro de supervisores das unidades</p>
                   </div>
               </div>
           </a>
       </div>
   </div>
    }
    {user.type === "employee" &&
         <div className="flex flex-col w-full mt-4 mb-8">        
         <div className="flex items-center justify-between flex-row">
             <div>
                 <h1 className="font-semibold text-2xl">Seja bem vindo {user.name || 'Usuário'}</h1>
                 <p className="text-gray-400">Abaixo acompanhe todo os processos</p>
             </div>
         </div>
         <div className="flex flex-col w-full lg:flex-row mt-6 gap-6">
             <a href='/' className="flex flex-col w-full lg:w-6/12 p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-cyan-500 to-blue-500">
                 <div>
                     <div className="font-bold roboto text-3xl text-center">Home</div>
                     <div className="text-xs  text-center">
                         <p>Acessar página inicial</p>
                     </div>
                 </div>
             </a>
             <a href={`/unidades/${user.unitObj}/setores/`} className="flex flex-col w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l from-[#48d6a8] to-green-600">
                 <div>
                     <div className="font-bold roboto text-center text-3xl">Setores</div>
                     <div className="text-xs  text-center">
                         <p>Informações por setor da unidade</p>
                     </div>
                 </div>
             </a>
             <a href={`/unidades/${user.unitObj}/pacientes/`}  className="flex flex-col  w-full lg:w-6/12  p-4 rounded-md bg-white shadow-md shadow-[#dadee8] text-white bg-gradient-to-l to-[#f76a2d] from-[#efa65f]">
                 <div>
                     <div className="font-bold roboto text-center text-3xl">Pacientes</div>
                     <div className="text-xs  text-center">
                         <p>Cadastro de pacientes das unidades</p>
                     </div>
                 </div>
             </a>
         </div>
     </div>
    }
    
    </>
    )

}