import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { createUnits, createUser, getUnits, getUsers } from "../../providers/ApiProvider";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";


enum Roles {
    ADMIN = "ADMIN",
    USER = "USER",
    GESTOR = "GESTOR",
    COLABORADOR = "COLABORADOR"
}


interface IFormInputs {
    name: string
    email: string
    position: string
    unit: string
    matricula: string
    scopes?: string[]
    type: string
  }

export default function FormUser() {
    let { id } = useParams();
    const user = JSON.parse(Cookies.get('jwt') || "{}"); 
    const [loading, setLoading] = React.useState<boolean>(id !== undefined ? true : false)
    const [units, setUnits] = React.useState<any[]>([])
    const Schema = yup.object().shape({
        name: yup.string()
        .label('Nome')
        .required("Digite um nome válido"),
        email: yup.string()
        .label('Email')
        .email("Digite um email válido")
        .required("Digite um email válido"),
        position: yup.string()
        .label('Cargo')
        .required("Digite um cargo válido"),
        matricula: yup.string()
        .label('Matricula')
        .required("Digite a matricula"),        
        unit: yup.string(),
        type: yup.string()
        .label('Tipo')
        .required("Digite um tipo válido"),
    });    

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
      } = useForm<any>({
        mode: "onTouched",
        resolver: yupResolver(Schema)
      });
    
      const submitForm = async (data:any) => {
        setLoading(true);
        let response;
        if (id !== undefined){
            response = await createUser(data, id);
        } else {
            response = await createUser(data);
        }
        setLoading(false);
      }

    useEffect(() => {
        setLoading(true);

        if (id !== undefined){
            const fetchUser = async () => {
                const response = await getUsers(id)
                setLoading(false);
                if (response !== null) {
                    Object.entries(response).forEach(
                        ([name, value]: any) => {
                            setValue(name, value, { shouldValidate: true })});
                }
            }
            fetchUser()
        }        
        const fetchData = async () => {
            let units = await getUnits()
            if (id === undefined) setLoading(false);
            if (units !== null) {
                if (user.type === "gestor") units = units.filter((item: any) => { return item.gestor === user._id })
                if (user.type === "supervisor") units = units.filter((item: any) => { return item._id === user.unit })
                setUnits(units)
            }
        }
        fetchData()
    }, [])

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-row justify-between items-center">
                <Breadcrumb title="Colaboradores" links={[
                    {title: "Home", link: "/", class: "text-gray-500"},
                    {title: "Colaboradores", link: "/usuarios", class: "text-gray-500"},
                    {title: id ? "Editar" : "Cadastro", link: "/usuarios/create", class: "text-green-500"},
                ]} />
            </div>
            <div className="flex md:flex-row flex-col justify-between items-center gap-4">            
                <div className="flex flex-col w-full">
                    <Card classes=" p-4">
                        <div className="flex flex-col gap-4">
                           {loading && (
                               <div className="flex flex-col items-center justify-center">  
                                    <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-green-500">Carregando...</span>
                                </div>
                            )}
                            {!loading && (
                                <form className='w-full my-6' autoComplete='off'  onSubmit={handleSubmit((data) => {console.log(data);submitForm(data)})}>
                                <div className="relative z-0 w-full mb-6 group">
                                    <input autoComplete='off'  type="text" {...register('name')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                    <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Nome da Colaborador
                                    </label>
                                    {errors.name && <p className="text-red-500 mb-4 text-xs italic">{errors.name.message?.toString()}</p>}
                                </div>
                                <div className="relative z-0 w-full mb-6 group">
                                    <input autoComplete='off' disabled={(id !== undefined)}  type="email" {...register('email')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                    <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Email
                                    </label>
                                    {errors.email && <p className="text-red-500 mb-4 text-xs italic">{errors.email.message?.toString()}</p>}
                                </div>
                                <div className="relative z-0 w-full mb-6 group">
                                    <input autoComplete='off'  type="text" {...register('position')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                    <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Cargo / Função
                                    </label>
                                    {errors.position && <p className="text-red-500 mb-4 text-xs italic">{errors.position.message?.toString()}</p>}
                                </div>
                                <div className="relative z-0 w-full mb-6 group">
                                    <input autoComplete='off'  type="text" {...register('matricula')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                    <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Matricula
                                    </label>
                                    {errors.matricula && <p className="text-red-500 mb-4 text-xs italic">{errors.matricula.message?.toString()}</p>}
                                </div>
                                <div className="flex flex-row gap-4">
                                    <div className="relative z-0 w-full mb-6 group w-6/12">
                                        <select autoComplete='off' {...register('type')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required>
                                            <option value="">Selecione o nivel de acesso</option>
                                            {user && user.type === "admin" && <option value="admin">Administrador</option>}
                                            {user && user.type === "admin" && <option value="gestor">Gestor</option>}
                                            {user && user.type === "gestor" && <option value="supervisor">Supervisor</option>}
                                            {user && user.type === "supervisor" && <option value="employee">Colaborador</option>}
                                        </select>
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Tipo de Acesso
                                        </label>
                                        {errors.type && <p className="text-red-500 mb-4 text-xs italic">{errors.type.message?.toString()}</p>}
                                    </div>      
                                    <div className="relative z-0 mb-6 group w-6/12"> 
                                        <select autoComplete='off' {...register('unit')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" ">
                                            <option value="">Selecione a unidade</option>
                                            {units && units.length >0 && units.map((unit, index) => (
                                                <option key={index} value={unit._id}>{unit.name} - {unit.city}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Unidade de atendimento
                                        </label>
                                        {errors.unit && <p className="text-red-500 mb-4 text-xs italic">{errors.unit.message?.toString()}</p>}
                                    </div> 

                                </div>
                                <div className="hidden relative z-0 w-full mb-6 group w-3/12 gap-1 flex flex-col">
                                        <h2 className="font-medium text-xs"> Funções disponiveis</h2>
                                        <div className="flex flex-row gap-2  text-xs">
                                            <input type="checkbox" {...register('scopes')} value={'dashboard'} className="peer" /> Dashboards
                                        </div>
                                        <div className="flex flex-row gap-2 text-xs">
                                            <input type="checkbox" {...register('scopes')}  value={'create'} className="peer" /> Cadastros
                                        </div>
                                        <div className="flex flex-row gap-2 text-xs">
                                            <input type="checkbox" {...register('scopes')} value={'edit'} className="peer" /> Edição
                                        </div>
                                        <div className="flex flex-row gap-2 text-xs">
                                            <input type="checkbox" {...register('scopes')} value={'delete'} className="peer" /> Exclusão
                                        </div>
                                    </div>

                                <button type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                                    Salvar
                                </button>
                            </form>   
                            )}

                            
                        </div>


                    </Card>
                </div>
            </div>
        </div>
    )
}