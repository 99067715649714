import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { useNavigate, useParams } from "react-router-dom";
import { addToLeito, deleteLeito, deleteSetor, deleteUnits, getBeds, getPatients, getUnits, removePaciente } from "../../providers/ApiProvider";
import Cookies from "js-cookie";
import moment from 'moment';
import { fugulin } from '../../../../LamarkFugilin/api/src/Service/Vendors/Ingera';


export default function Leitos() {

    const [obj, setObj] = React.useState<any>([])
    const [loading, setLoading] = React.useState<boolean>(true)
    const navigate = useNavigate();
    const {uuid,edit} = useParams()
    const user = JSON.parse(Cookies.get('jwt') || "{}");
    const [pacientes,setPacientes] = React.useState<any>([])
    const [modal,setModal] = React.useState<boolean>(false)
    const [leito,setLeito] = React.useState<string>("")
    const [paciente,setPaciente] = React.useState<any>("")

    const loadStatus = async () => {
        getPatients(uuid).then((res) => {
            setPacientes(res)
        })
        if (edit !== undefined) 
            getBeds(edit).then((res) => {
                setObj(res)
                setLoading(false)
            })
    }

    const checkTurno = (data: any | null, patient: any) => {

        const hoje = moment(new Date())
        const turno = hoje.hour() <= 12 ? 0 : 1
        const turnoData = moment(data?.createdAt).hour() <= 12 ? 0 : 1
        const sameTurno = turno === turnoData
        const sameDay = hoje.isSame(data, 'day')
        //check diff minutes
        const diff = moment(new Date()).diff(moment(data), 'minutes') > 720
        return sameDay && sameTurno && diff && data?.patient === patient
    }

    const somaFugulinDoDia = (data: any) => {
        const day = moment(new Date())
        let score = 0
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const sameDay = day.isSame(moment(element.createdAt), 'day')
            if (sameDay)
                score += element.score
        }
        return score
    }

    useEffect(() => {
        loadStatus()
    }, [])

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-row justify-between items-center">
                <Breadcrumb title="Leitos" links={[
                    {title: "Home", link: "/", class: "text-gray-500"},
                    {title: "Unidades", link: "/unidades", class: "text-gray-500"},
                    {title: "Setores", link: `/unidades/${uuid}/setores/`, class: "text-gray-500"},
                    {title: "Leitos", link: `#`, class: "text-green-500"},
                ]} />
                {
                    <button onClick={() => navigate(`/unidades/${uuid}/setores/${edit}/leitos/create`)} className="flex flex-row items-center gap-2 bg-green-500 text-white px-4 py-2 rounded shadow-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        <span>Adicionar</span>
                    </button>
                }
            </div>
            {modal && (
            <div className="fixed bg-[rgba(0,0,0,.5)] w-screen h-screen top-0 left-0 z-[1000]">
                <div className="flex flex-col justify-center items-center w-full h-full">
                    <div className="flex flex-col w-1/2 bg-white rounded-lg p-4">
                        <div className="flex flex-row justify-between items-center">
                            <h1 className="font-bold text-gray-700">Adicionar paciente</h1>
                            <button onClick={() => {setModal(false); setLeito('')}} className="flex flex-row items-center gap-2 bg-red-500 text-white px-4 py-2 rounded shadow-lg">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                                <span>Fechar</span>
                            </button>
                        </div>
                        <div className="flex flex-row mt-6 gap-4">
                            <select onChange={(e:any) => {
                                setPaciente(e.target.value)
                            }} className="w-full border border-gray-300 rounded-md px-4 py-2">
                                <option value="">Selecione um paciente</option>
                                {pacientes && pacientes.map((item: any, index: number) => (
                                    (item.bed === undefined || item.bed === null) && (
                                        <option key={index} value={item.uuid}>{item.name}</option>
                                    )
                                ))}
                            </select>
                            <button onClick={() => {
                                setLoading(true)
                                if (leito !== "") {
                                    addToLeito({bed:leito,patient:paciente}, paciente).then((res) => {                                        
                                        setModal(false)
                                        setLeito("")
                                        loadStatus()
                                        setLoading(false)
                                    })
                                }

                            }} className="flex flex-row items-center gap-2 bg-green-500 text-white px-4 py-2 rounded shadow-lg">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                                <span>Selecionar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            )}
            <div className="flex md:flex-row flex-col justify-between items-center gap-4">            
                <div className="flex flex-col w-full">
                    <Card classes="md:min-h-[70vh] p-4">
                        <div className="flex flex-col gap-4">
                           {loading && (
                               <div className="flex flex-col items-center justify-center">  
                                    <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-green-500">Carregando...</span>
                                </div>
                            )}
                            {!loading && (
                                <div className="relative overflow-x-auto sm:rounded-lg">
                                    {/*<div className="pb-4 bg-white ">
                                        <label className="sr-only">Buscar</label>
                                        <div className="relative mt-1">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400 dark:text-gray-300" viewBox="0 0 24 24" fill="none">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35"/>
                                                    <circle cx="11" cy="11" r="8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                                </svg>
                                            </div>
                                            <input type="text" name="search" id="search" className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md leading-5 bg-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:placeholder-gray-400 sm:text-sm" placeholder="Buscar" />
                                        </div>
                                    </div>    */}
                                    <h1 className="font-bold text-gray-700">Setor: {obj && obj.sector.name}</h1>
                                    <h1 className="font-medium text-xs text-green-700">Unidade: {obj && obj.sector.unit?.name}</h1>                                    
                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Leito
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Paciente
                                                </th>
                                                <th scope="col" className="px-6 text-center py-3">
                                                    Score
                                                </th>
                                                <th scope="col" className="px-6 text-center py-3">
                                                    Ultimo Fugulin
                                                </th>
                                                <th scope="col" className="px-6 py-3  text-right">
                                                    Ações
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {obj && obj.beds?.map((item: any, index: number) => (
                                                <tr key={index} className="bg-white border-b hover:bg-gray-50">
                                                    <td onClick={() => navigate(`/unidades/${uuid}/setores/${edit}/leitos/${item.uuid}`)} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap cursor-pointer">
                                                        {item.name}
                                                    </td>
                                                    <td className="px-6 py-4 font-medium text-gray-900 cursor-pointer">
                                                        {(!item.isFree) ? (item.patient?.name) : "Vago" }
                                                    </td>
                                                    <td className="text-center">
                                                        {!item.isFree &&
                                                           `${somaFugulinDoDia(item.fugulin.sort((a:any,b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()))}`
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {(!item.isFree && item.fugulin.sort((a:any,b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0,1)[0]?.patient === item.patient?._id) &&
                                                            `${moment(item.fugulin.sort((a:any,b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0,1)[0]?.createdAt).format("DD/MM HH:mm")}`
                                                        }
                                                    </td>
                                                    {user && user.type === "employee" ?
                                                        <td className="px-6 py-4 flex justify-end gap-2 text-right">
                                                            {(item.isFree ) ? 
                                                                <a href={`#`} onClick={()=>{
                                                                    setModal(true);
                                                                    setLeito(item.uuid)
                                                                }} className="font-medium text-green-600 hover:underline">
                                                                    Adicionar Paciente 
                                                                </a>
                                                                :
                                                                <>
                                                                    {
                                                                        !checkTurno(item.fugulin.sort((a:any,b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0,1)[0],item.patient?._id) &&
                                                                        <a href={`/setor/${edit}/leito/${item.uuid}/fugulin/`} className="font-medium text-blue-600 hover:underline">
                                                                            Preencher Fugulin
                                                                        </a>

                                                                    }
                                                                    |
                                                                    <a href="#" onClick={async()=>{
                                                                        setLoading(true)
                                                                        await removePaciente(item.uuid)
                                                                        await loadStatus()
                                                                        getBeds(edit!).then((res) => {
                                                                            setObj(res)
                                                                            setLoading(false)
                                                                        })
                                                                    }} className="font-medium text-yellow-500 hover:underline">
                                                                        Liberar leito
                                                                    </a>
                                                                </>
                                                            }
                                                            {(item.isFree && user.type === "supervisor") && (<>|</>) }
                                                            {(item.isFree && user.type === "supervisor") &&
                                                                <a href="#" onClick={async()=>{
                                                                    setLoading(true)
                                                                    await deleteLeito(edit!,item.uuid)
                                                                    getBeds(edit!).then((res) => {
                                                                        setObj(res)
                                                                        setLoading(false)
                                                                    })
                                                                }} className="font-medium text-red-600 hover:underline">
                                                                    Excluir
                                                                </a>
                                                            }
                                                        </td>
                                                        :<td className="px-6 py-4 flex justify-end gap-2 text-right">
                                                        </td>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <nav className="flex items-center justify-between pt-4" aria-label="Table navigation">
                                        <span className="text-sm font-normal text-gray-500">Mostrando <span className="font-semibold text-gray-900">1-10</span> de <span className="font-semibold text-gray-900 ">1000</span></span>
                                        <ul className="inline-flex -space-x-px text-sm h-8">
                                            <li>
                                                <a href="#" className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Anterior</a>
                                            </li>
                                            <li>
                                                <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">1</a>
                                            </li>
                                            <li>
                                                <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Proximo</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>    
                            )}

                            
                        </div>


                    </Card>
                </div>
            </div>
        </div>
    )
}