import { Await, Navigate, useLoaderData, useOutlet } from "react-router-dom";
import { AuthProvider, useAuth } from "../providers/AuthProvider";
import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaFolder } from "@react-icons/all-files/fa/FaFolder";
import {HiOutlineChartSquareBar} from "@react-icons/all-files/hi/HiOutlineChartSquareBar";
import {AiFillMedicineBox} from "@react-icons/all-files/ai/AiFillMedicineBox";
import {MdAccountCircle} from "@react-icons/all-files/md/MdAccountCircle";
import {MdFace} from "@react-icons/all-files/md/MdFace";
import {HiOutlineOfficeBuilding} from '@react-icons/all-files/hi/HiOutlineOfficeBuilding';
import LogoSegeam from '../assets/images/logo-segeam.png';
import { CircleFlag } from "react-circle-flags";
import { Suspense } from "react";
import Cookies from "js-cookie";
import {FaSignOutAlt} from "@react-icons/all-files/fa/FaSignOutAlt";
import { MdDeck, MdFace6, MdFaceRetouchingNatural, MdFaceRetouchingOff, MdFacebook, MdHomeMini } from "react-icons/md";

export const AuthLayout = () => {
    const outlet = useOutlet();
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); 
    if (jwt === null || !jwt.token || !jwt.type) return <Navigate to="/login" />;
    if (jwt !== null && (jwt?.isBlock )) return <Navigate to="/login" />;
    return (
      <Suspense fallback={<div>Loading</div>}>
          <AuthProvider>
            <div className="flex flex-col min-h-screen bg-[#ecf0fa]">
              <nav className="w-full flex bg-white border-b border-[#dee4ec]">
                  <div className="container flex items-center justify-between w-full mx-auto px-2 py-4">
                    <div className="flex flex-row justify-center items-center gap-7">
                      <img className="max-h-20" alt="Logo" src={LogoSegeam}/>
                    </div>
                    <div className="flex flex-row justify-center items-center gap-4">
                      <CircleFlag  countryCode="br" height="20" className='mr-2 h-5' />
                      <a href="/logout" className="flex flex-row gap-2">
                        <FaSignOutAlt className="h-6"/> Sair
                      </a>
                    </div>
                  </div>
              </nav>
              <nav className="w-full flex bg-white border-b border-[#dee4ec]">
                  <div className="container flex flex-row items-center justify-start gap-4 text-[11px] w-full mx-auto px-2 py-3">
                    {jwt && jwt.type==="admin" && (
                      <>
                        <div onClick={() => window.location.href = "/"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">
                          <MdHomeMini className="text-xl"/>
                          <h1 className="roboto font ">Lar</h1>
                        </div> 
                        <div onClick={() => window.location.href = "/dashboard"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">
                          <HiOutlineChartSquareBar className="text-xl"/>
                          <h1 className="roboto font ">Dashboard</h1>
                        </div>                
                        <div onClick={() => window.location.href = "/unidades"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <HiOutlineOfficeBuilding className="text-xl"/>
                          <h1 className="roboto font">Unidades</h1>
                        </div>
                        <div onClick={() => window.location.href = "/usuarios"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <MdAccountCircle className="text-xl"/>
                          <h1 className="roboto font">Usuários</h1>
                        </div>
                        </>
                    )}
                    {jwt.type === "supervisor" && (
                      <>
                        <div onClick={() => window.location.href = "/"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">
                          <MdHomeMini className="text-xl"/>
                          <h1 className="roboto font ">Lar</h1>
                        </div> 
                        <div onClick={() => window.location.href = "/usuarios"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <MdAccountCircle className="text-xl"/>
                          <h1 className="roboto font">Colaboradores</h1>
                        </div>
                        <div onClick={() => window.location.href = `/unidades/${jwt.unitObj || ''}/setores`} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <MdDeck  className="text-xl"/>
                          <h1 className="roboto font">Setores</h1>
                        </div>

                        </>
                    )}
                    {jwt.type === "gestor" && (
                      <>
                        <div onClick={() => window.location.href = "/"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">
                          <MdHomeMini className="text-xl"/>
                          <h1 className="roboto font ">Lar</h1>
                        </div> 
                        <div onClick={() => window.location.href = "/unidades"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <HiOutlineOfficeBuilding className="text-xl"/>
                          <h1 className="roboto font">Minhas Unidades</h1>
                        </div>
                        <div onClick={() => window.location.href = "/usuarios"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <MdAccountCircle className="text-xl"/>
                          <h1 className="roboto font">Supervisores</h1>
                        </div>
                        </>
                    )}
                    {jwt.type === "employee" && (
                      <>
                        <div onClick={() => window.location.href = "/"} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">
                          <MdHomeMini className="text-xl"/>
                          <h1 className="roboto font ">Lar</h1>
                        </div> 
                        <div onClick={() => window.location.href = `/unidades/${jwt.unitObj || ''}/pacientes`} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <MdFace className="text-xl"/>
                          <h1 className="roboto font">Pacientes</h1>
                        </div>
                        <div onClick={() => window.location.href = `/unidades/${jwt.unitObj || ''}/setores`} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <AiFillMedicineBox className="text-xl"/>
                          <h1 className="roboto font">Setores</h1>
                        </div>           
                        <div onClick={() => window.location.href = `/fugulins`} className="justify-center flex flex-row items-center gap-2 border-r pr-4 cursor-pointer hover:text-green-700 hover:font-semibold text-[#7b8191]">              
                          <FaFolder className="text-xl"/>
                          <h1 className="roboto font">Fugulins</h1>
                        </div>                        
                        </>
                    )}
                  </div>
              </nav>
              <div className="container flex flex-row items-center justify-start gap-4 w-full mx-auto px-2 py-3">
                {outlet}
              </div>
            </div>
          </AuthProvider>
    </Suspense>
    )
  };