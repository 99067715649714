import { CircleFlag } from "react-circle-flags";
import { Link, Outlet } from "react-router-dom";
import LoginImage from '../assets/images/logo-segeam.png'
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "../providers/AuthProvider";

export function Layout() {
    return (
      <AuthProvider>
      <main className={`bg-primary-100 flex min-h-screen flex-col lg:flex-row items-center justify-center lg:justify-between p-12 lg:p-24`}>
        <div className='flex flex-col items-center justify-center lg:flex-1'>
            <img
                className='max-w-[150px] lg:max-w-[200px] mb-10' 
                src={LoginImage}
                alt='Segeam'
            />
            <h1 className='font-semibold text-gray-800 text-3xl mb-7'>Fugulin</h1>
            <p className='text-gray-600 text-center text-sm font-extralight'>
               Seja bem vindo <span className='text-[#3B843B] font-semibold'>Fugulin</span>, gestão do serviços de saúde, que permite a avaliação e identificação do grau de dependência do paciente e possibilita a distribuição de profissionais de enfermagem de acordo com o tipo de dependência. .
            </p>
        </div>
        <ToastContainer />
        <div className='flex flex-col items-center justify-center p-4 w-full lg:basis-6/12'>
          <div className='rounded-2xl min-h-[60vh] shadow-sm bg-white flex-col p-10 w-full flex justify-between items-center'>
            <Outlet/>
            <div className='flex flex-row justify-between w-full items-center'>
                <div className='w-[20px] flex flex-row text-xs items-center'>
                  <CircleFlag countryCode="br" height="20" className='mr-2' /> Brasil
                </div>
                <div>
                  <a href='contact-us' className='text-[#3B843B] opacity-90 text-xs font-semibold px-2' title='Contact'>Contato</a>
                  <a href='privacy' className='text-[#3B843B] opacity-90 text-xs font-semibold px-2' title='Terms'>Termos</a>
                </div>
            </div>
          </div>
        </div>
      </main>
      </AuthProvider>
    );
  }