'use client'

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import Code from '../start/code';



export default function Login() {
    const { checkEmail, checkPassword, resetPassword, login } = useAuth();
    const [forgot,setForgot] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const [step,setStep] = useState<number>(0);
    const [uuid,setUuid] = useState<string>('');
    const [email,setEmail] = useState<string>('');
    const [token,setToken] = useState<string>('');
    const errMess = {
        email: "Digite um email valido",
        password: "Digite uma senha válida",
    };
    const LoginSchema = yup.object().shape({
        email: yup.string()
            .label('Email Address')
            .required(errMess.email)
            .email('Email inválido'),
        password: !forgot && step>0 ? yup.string()
            .label('Password')
            .required(errMess.password)
            .min(6, 'Senha deve ter no mínimo 6 caracteres')
            .required('Senha é obrigatória')
            .max(32) : yup.string()
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onTouched",
        resolver: yupResolver(LoginSchema)
      });
    
      const submitForm = async (data:any) => {
        setLoading(true);
        setEmail(data.email);
        if (forgot) {
            const response = await resetPassword(data);
            setLoading(false);
            return
        }
        if (step === 0){
            const response = await checkEmail(data);
            console.log("response",response)
            setLoading(false);
            if(response.uuid !== undefined && response.uuid.length > 5){
                setUuid(response.uuid);
                setStep(1);
            }
        }
        else if (step === 1){
            const response = await checkPassword({...data,uuid});
            setLoading(false);
            if(response.active !== undefined && response.active === true){
                setToken(response.token);
                setStep(2);
            }
        }
      }

    const checkCode = async (data:any) => {
        setLoading(true);
        await login({code:data,uuid,email,token});
        setLoading(false);
    }


  return (
    <>  
        {loading &&
            <div className='fixed z-50 top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center'>
                <div className='bg-white rounded-lg p-8'>
                    <h1 className='text-2xl font-semibold text-gray-800'>Carregando...</h1>
                </div>
            </div>
        }
        {step <2  &&
            <div className='flex flex-col justify-center items-center'>
                <h1 className='font-semibold text-gray-800 text-3xl mb-2'>{forgot ? 'Esqueci a senha' : 'Entrar'}</h1>
                <h2 className='text-sm text-gray-400'>{forgot ? 'Se você esqueceu sua senha, por favor preencha o email abaixo.' :'Bem vindo ao indicador de enfermagem de escala'}</h2>
            </div>
        }
        {step === 0 &&
            <>                
                <form className='w-full my-6' autoComplete='off'  onSubmit={handleSubmit((data) => {console.log(data);submitForm(data)})}>
                    <div className="relative z-0 w-full mb-6 group">
                        <input autoComplete='off'  type="email" {...register('email')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                        {errors.email && <p className="text-red-500 mb-4 text-xs italic">{errors.email.message?.toString()}</p>}
                    </div>
                    <button disabled={loading} type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                        {forgot ? 'Esqueci a senha' : 'Entrar'}
                    </button>
                </form>               
            </>
        }
        {step === 1 &&
            <>                
                <form className='w-full my-6' autoComplete='off'  onSubmit={handleSubmit((data) => {console.log(data);submitForm(data)})}>
                    <div className="relative z-0 w-full mb-6 group">
                        <input autoComplete='off'  type="email" {...register('email')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                        {errors.email && <p className="text-red-500 mb-4 text-xs italic">{errors.email.message?.toString()}</p>}
                    </div>
                    {!forgot &&
                        <>
                            <div className="relative z-0 w-full group mb-3">
                                <input type="password" {...register('password')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                <label htmlFor="password" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Senha</label>
                                {errors.password && <p className="text-red-500 mb-4 text-xs italic">{errors.password.message?.toString()}</p>}
                            </div> 
                            <div className="flex items-center justify-end mb-6">
                                <p onClick={()=>setForgot(!forgot)} className="text-xs text-[#3B843B] cursor-pointer hover:text-gray-900 dark:hover:text-gray-300">Esqueci a senha?</p>
                            </div>                     
                        </>
                    }              
                    <button disabled={loading}  type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                        {forgot ? 'Esqueci a senha' : 'Entrar'}
                    </button>
                </form>               
            </>
        }
        {step === 2 &&
            <>
                <Code checkCode={checkCode}/>
            </>
        }

                     
    </>
  )
}
