import { useEffect, useState } from "react";
import Verified2FA from "./verified-2fa";
import NewPassword from "./new-password";
import { useParams } from "react-router-dom";
import Success from "./success";
import Code from "./code";
import { validToken } from "../../providers/ApiProvider";
import { Loading } from "../../components/Loading";

export default function FlowStart(){

    const [step, setStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string>('');
    const [qrCode, setQR] = useState<string>('');
    const [first,setFirst] = useState<boolean>(true);
    let { uuid } = useParams();

    useEffect(() => {
        const validAsync = async () => {
            setLoading(true)
            setFirst(false)
            const data = await validToken(uuid || '')
            setLoading(false)
            if(data !== ""){
                setToken(data.uuid)
                setQR(data.qrCode)
                setStep(1)
            }
        }
        if(uuid && first){
            setFirst(false)
            validAsync()
        }
    }, [])

    return (
        <>  
            {loading && (<Loading/>)}
            {(step === 1 && uuid) && (<NewPassword uuid={token} setStep={setStep}/>) }
            {step === 2 && (<Verified2FA uuid={token} QR={qrCode} setStep={setStep}/>) }
            {step === 3 && (<Success/>)}
        </>
    )

}