import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { createUnits, getUnits, getUsers } from "../../providers/ApiProvider";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";


enum Roles {
    ADMIN = "ADMIN",
    USER = "USER",
    GESTOR = "GESTOR",
    COLABORADOR = "COLABORADOR"
}
interface IEmployee {
    _id?: number;
    name: string;
    email: string;
    password?: string;
    role: Roles;
}

interface IFormInputs {
    name: string
    city: string
    state: string
    gestor: any
    supervisor?: string
    address: string
    diurno: number
    noturno: number
  }

  const states = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' }
  ];

export default function FormUnit() {
    let { id } = useParams();
    const [obj, setObj] = React.useState([])
    const [loading, setLoading] = React.useState<boolean>(id !== undefined ? true : false)
    const [gestores, setGestores] = React.useState<IEmployee[]>([])
    const [supervisor, setSupervisor] = React.useState<IEmployee[]>([])
    const Schema = yup.object().shape({
        name: yup.string()
        .label('Nome')
        .required("Digite um nome válido"),
        city: yup.string()
        .label('Cidade')
        .required("Digite uma cidade válida"),
        state: yup.string()
        .label('Estado')
        .max(2, "Digite apenas a sigla")
        .required("Digite um estado válido"),        
        address: yup.string()
        .label('Endereço')
        .required("Digite um endereço válido"),
        gestor: yup.string()
        .label('Gestor'),
        //.required("Escolha o gestor da unidade"),        
        diurno: yup.number()
        .label('Horas diurno')
        .min(10, "Digite um valor válido")
        .required("Digite um valor válido"),
        noturno: yup.number()
        .label('Horas noturno')
        .min(10, "Digite um valor válido")
        .required("Digite um valor válido"),
    });

    useEffect(() => {
        setLoading(true)
        getUsers(undefined,'gestor').then((res) => {
            setGestores(res)
            setLoading(false)
        })
    }, [])

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm<IFormInputs>({
        mode: "onTouched",
        resolver: yupResolver(Schema)
      });
    
      const submitForm = async (data:any) => {
        setLoading(true);
        let response;
        if (id !== undefined){
            response = await createUnits(data, id);
        } else {
            response = await createUnits(data);
        }
        setLoading(false);
        if (response !== null) {
            toast.success("Unidade cadastrada com sucesso!");
        }
      }

    useEffect(() => {
        if (id !== undefined){
            const fetchData = async () => {
                const response = await getUnits(id)
                setLoading(false);
                if (response !== null) {
                    Object.entries(response).forEach(
                        ([name, value]: any) => {
                            setValue(name, value, { shouldValidate: true })
                        });
                }
            }
            fetchData()
        }
    }, [])

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-row justify-between items-center">
                <Breadcrumb title="Unidades" links={[
                    {title: "Home", link: "/", class: "text-gray-500"},
                    {title: "Unidades", link: "/unidades", class: "text-gray-500"},
                    {title: id ? "Editar" : "Cadastro", link: "/unidades/create", class: "text-green-500"},
                ]} />
            </div>
            <div className="flex md:flex-row flex-col justify-between items-center gap-4">            
                <div className="flex flex-col w-full">
                    <Card classes=" p-4">
                        <div className="flex flex-col gap-4">
                           {loading && (
                               <div className="flex flex-col items-center justify-center">  
                                    <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-green-500">Carregando...</span>
                                </div>
                            )}
                            {!loading && (
                                <form className='w-full my-6' autoComplete='off'  onSubmit={handleSubmit((data) => {console.log(data);submitForm(data)})}>
                                <div className="relative z-0 w-full mb-6 group">
                                    <input autoComplete='off'  type="text" {...register('name')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                    <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Nome da Unidade</label>
                                    {errors.name && <p className="text-red-500 mb-4 text-xs italic">{errors.name.message?.toString()}</p>}
                                </div>
                                <div className="relative z-0 w-full mb-6 group">
                                    <input autoComplete='off'  type="text" {...register('address')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                    <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                        Endereço
                                    </label>
                                    {errors.address && <p className="text-red-500 mb-4 text-xs italic">{errors.address.message?.toString()}</p>}
                                </div>
                                <div className="relative z-0 w-full mb-6 group">
                                    <div className="relative z-0 w-full mb-6 group w-3/12">
                                        <select autoComplete='off' {...register('gestor')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="">
                                            <option value="">Selecione um gestor</option>
                                            {gestores.map((state, index) => (
                                                <option key={index} value={state._id}>{state.name}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Gestor
                                        </label>
                                        {errors.gestor && <p className="text-red-500 mb-4 text-xs italic">{errors.gestor.message?.toString()}</p>}
                                    </div>  
                                </div>                                
                                <div className="flex flex-row gap-4">
                                    <div className="relative z-0 w-full mb-6 group w-3/12">
                                        <select autoComplete='off' {...register('state')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required>
                                            {states.map((state, index) => (
                                                <option key={index} value={state.value}>{state.label}</option>
                                            ))}
                                        </select>
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Estado
                                        </label>
                                        {errors.state && <p className="text-red-500 mb-4 text-xs italic">{errors.state.message?.toString()}</p>}
                                    </div>                                    
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input autoComplete='off'  type="text" {...register('city')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Cidade
                                        </label>
                                        {errors.city && <p className="text-red-500 mb-4 text-xs italic">{errors.city.message?.toString()}</p>}
                                    </div>                                    
                                    
                                </div>
                                <div className="flex flex-row gap-4">
                                    <div className="relative z-0 w-full mb-6 group w-3/12">
                                        <input autoComplete='off'  type="number" {...register('diurno')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Horas totais diurno
                                        </label>
                                        {errors.diurno && <p className="text-red-500 mb-4 text-xs italic">{errors.diurno.message?.toString()}</p>}
                                    </div>                                    
                                    <div className="relative z-0 w-full mb-6 group w-3/12">
                                        <input autoComplete='off'  type="number" {...register('noturno')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Horas totais noturno
                                        </label>
                                        {errors.noturno && <p className="text-red-500 mb-4 text-xs italic">{errors.noturno.message?.toString()}</p>}
                                    </div>                               
                                    
                                </div>
                                <button type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                                    Salvar
                                </button>
                            </form>   
                            )}

                            
                        </div>


                    </Card>
                </div>
            </div>
        </div>
    )
}