import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { toast } from "react-toastify";
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { createPatient, getBeds, getUnits } from "../../providers/ApiProvider";
import Cookies from "js-cookie";



interface IFormInputs {
    name: string
    gender: string
    age: number
    risk: number
    
  }

export default function FormPatient() {
    let { uuid, setor, edit } = useParams();
    const user = JSON.parse(Cookies.get('jwt') || "{}");

    const [estado,setEstado] = React.useState<number>(0)    
    const [oxigenacao,setOxigencao] = React.useState<number>(0)
    const [sinais,setSinais] = React.useState<number>(0)
    const [motilidade,setMotilidade] = React.useState<number>(0)
    const [deambulacao,setDeambulacao] = React.useState<number>(0)
    const [alimentacao,setAlimentacao] = React.useState<number>(0)
    const [cuidado,setCuidado] = React.useState<number>(0)
    const [eliminacao,setElimincacao] = React.useState<number>(0)
    const [terapeutica,setTerapeutica] = React.useState<number>(0)

    const [loading, setLoading] = React.useState<boolean>(edit !== undefined ? true : false)
    const Schema = yup.object().shape({
        name: yup.string()
        .label('Nome')
        .required("Digite um nome válido"),
        gender: yup.string()
        .label('Gênero')
        .required("Digite um gênero válido"),
        age: yup.number()
        .label('Idade')
        .min(0, "Digite uma idade válida (0 a 110)")
        .max(110, "Digite uma idade válida (0 a 110)")
        .required("Digite uma idade válida"),
        risk: yup.number()
        .label('Idade')
        .min(0, "Digite uma idade válida (0 a 110)")
        .max(110, "Digite uma idade válida (0 a 110)")
        .required("Digite uma idade válida"),
        
    });

    const defautlValues = {
        name: "",
        age:0,
        gender: '',
        risk: 0
    }
    const [obj,setObj] = React.useState<any>({})
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm<IFormInputs>({
        mode: "onTouched",
        defaultValues: defautlValues,
        resolver: yupResolver(Schema)
      });
    const navigate = useNavigate();
      const submitForm = async (data:any) => {
        setLoading(true);
        const resp = await createPatient(data, uuid!);
        setLoading(false);
      }
      
      useEffect(() => {
        if (uuid !== undefined){
            setLoading(true);
            const fetchData = async () => {
                const response = await getUnits()
                setLoading(false);
                if (response !== null) {
                    const unidade = response.find((item:any) => item.uuid === uuid)
                    console.log(unidade)
                    setObj(unidade)
                }
            }
            fetchData()
        }
    }, [])

    return (
        <div className="flex flex-col gap-3 w-full">
            <div className="flex flex-row justify-between items-center">
                <Breadcrumb title="Adicionar Paciente" links={[

                ]} />
            </div>
            <div className="flex md:flex-row flex-col justify-between items-center gap-4">            
                <div className="flex flex-col w-full">
                    <Card classes=" p-4">
                        <div className="flex flex-col">
                           {loading && (
                               <div className="flex flex-col items-center justify-center">  
                                    <svg xmlns="http://www.w3.org/2000/svg" className="animate-spin h-10 w-10 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v4H5a1 1 0 100 2h4v4a1 1 0 102 0v-4h4a1 1 0 100-2h-4V4a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-green-500">Carregando...</span>
                                </div>
                            )}
                            {!loading && (                            
                                <form className='w-full mb-6' autoComplete='off'  onSubmit={handleSubmit((data) => {console.log(data);submitForm(data)})}>
                                    <h1 className="text-2xl font-bold text-gray-900">{obj && obj.name}</h1>
                                    <h2 className="pb-2 text-xs">{obj && obj.address}, {obj && obj.city}-{obj && obj.state}</h2>
                                    <h2>Preencha todos os dados abaixo para adicionar um novo paciente</h2>
                                    <h2 className="text-xs text-gray-500">Os campos marcados com * são obrigatórios</h2>
                                    <div className="relative z-0 w-full mb-6 group">
                                        <input autoComplete='off'  type="text" {...register('name')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Nome do paciente
                                        </label>
                                        {errors.name && <p className="text-red-500 mb-4 text-xs italic">{errors.name.message?.toString()}</p>}
                                    </div>
                                    <div className="flex flex-row gap-3">
                                    <div className="relative z-0 mb-6 group w-4/12">
                                        <input autoComplete='off'  type="number" {...register('age')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />                                            
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Idade
                                        </label>
                                        {errors.age && <p className="text-red-500 mb-4 text-xs italic">{errors.age.message?.toString()}</p>}
                                    </div>      
                                    <div className="relative z-0 mb-6 group w-4/12">
                                        <select autoComplete='off' {...register('gender')} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required>
                                            <option value="">Selecione o gênero</option>
                                            <option value="M">Masculino</option>
                                            <option value="F">Feminino</option>
                                        </select>
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                            Gênero
                                        </label>
                                        {errors.gender && <p className="text-red-500 mb-4 text-xs italic">{errors.gender.message?.toString()}</p>}
                                    </div> 
                                    <div className="z-0 mb-6 group w-4/12 gap-2 flex flex-row items-center">
                                        <input type="checkbox" {...register('risk')} value={1} className="peer" /> 
                                        <label className="">
                                            Paciente da área psiquiátrica, com intercorrência clínica ou cirúrgica associada
                                        </label>
                                    </div> 
                                </div>
                                    <button type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
                                        Salvar
                                    </button>
                                </form>   
                            )}

                            
                        </div>


                    </Card>
                </div>
            </div>
        </div>
    )
}