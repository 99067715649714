'use client'

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PasswordStrengthBar from 'react-password-strength-bar';
import { useEffect, useState } from 'react';
import { resetSavePassword, savePassword, validTokenReset } from '../../providers/ApiProvider';
import { Loading } from '../../components/Loading';
import { useParams } from 'react-router-dom';

export default function ResetPassword() {
    const {uuid} = useParams();
    const errMess = {
        passwordStep: "Por favor digite uma senha válida",
    };
    const Schema = yup.object().shape({
        passwordStep: yup.string()
            .min(12, 'Senha deve ter no minimo 12 caracteres')
            .required('Senha obrigatória')
            .max(32, 'Senha deve ter no máximo 32 caracteres')
            .test('password-strength', 'Senha deve seguir todas as regras', function(value){
                return rules.every((rule) => rule === true)
            })
            ,
        passwordConfirmation: yup.string()
            .test('passwords-match', 'Senhas diferentes', function(value){
            return password === value
            })
        });
    const [password,setPass] = useState<string>('');
    const [score,setScore] = useState<number>(0);
    const [loading,setLoading] = useState<boolean>(false);

    const [rules, setRules] = useState<boolean[]>([false,false,false,false,false,false])
    useEffect(() =>{
        let rules = [false,false,false,false,false,false]
        if (password.match(/[a-z]/g)) rules[0] = true;
        if (password.match(/[A-Z]/g)) rules[1] = true;
        if (password.match(/[0-9]/g)) rules[2] = true;
        if (password.match(/[^\w\s]/)) rules[3] = true;
        if (password.length >= 10) rules[4] = true;
        if (password.slice(2,password.length-2).match(/[A-Z]/g) && password.slice(2,password.length-2).match(/[0-9]/g) &&  password.slice(2,password.length-2).match(/[^\w\s]/g)){
            rules[5] = true;
        }
        setRules(rules);
    },[password])
    const [first,setFirst] = useState<boolean>(true);
    
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onTouched",
        resolver: yupResolver(Schema)
      });
    
      const submitForm = async (data:any) => {
        setLoading(true)
        const response = await resetSavePassword(uuid!,data.passwordStep)
        if (response.status === 200){
            setLoading(false)
            window.location.href = '/login'
        }
      }


    useEffect(() => {
        const validAsync = async () => {
            setLoading(true)
            const data = await validTokenReset(uuid || '')
            if (data.status === 200){
                setLoading(false)
            }else{
                setLoading(false)
            //    window.location.href = '/login'
            }
        }
        if(uuid && first){
            setFirst(false)
            validAsync()
        }
    }, [])


  return (
    <>
        {loading && (<Loading/>)}
       <div className='flex flex-col justify-center items-center'>
            <h1 className='font-semibold text-gray-800 text-3xl mb-2'>Senha</h1>
            <h2 className='text-sm text-gray-400'>Escolha uma senha que siga as regras:</h2>
            <ul className='text-xs pt-4 list-disc'>
            <li className={`pb-1 font-medium ${password.length === 0 ? 'text-gray-600' : password.length > 0 && rules[0] ? 'text-green-600' : 'text-red-600'} `}>No mínimo 1 letra minusculo [a-z] e</li>
            <li className={`pb-1 font-medium ${password.length === 0 ? 'text-gray-600' : password.length > 0 && rules[1] ? 'text-green-600' : 'text-red-600'} `}>No mínimo 1 letra maiuscula [A-Z] e</li>
            <li className={`pb-1 font-medium ${password.length === 0 ? 'text-gray-600' : password.length > 0 && rules[2] ? 'text-green-600' : 'text-red-600'} `}>No mínimo 1 caracter númerico [0-9] e</li>
            <li className={`pb-1 font-medium ${password.length === 0 ? 'text-gray-600' : password.length > 0 && rules[3] ? 'text-green-600' : 'text-red-600'} `}>No mínimo 1 caracter especial: ~!@#$%^&*()-_+={}[]|\;:./?</li>            
            <li className={`pb-1 font-medium ${password.length === 0 ? 'text-gray-600' : password.length > 0 && rules[4] ? 'text-green-600' : 'text-red-600'} `}>Senha deve ter no minimo 10 caracteres, mas pode ser ter até 32 caracteres.</li>
            <li className={`pb-1 font-medium ${password.length === 0 ? 'text-gray-600' : password.length > 0 && rules[5] ? 'text-green-600' : 'text-red-600'} `}>Pelo menos 1 letra maiuscula, numero e caracter especial devem estar no meio da senha, e não apenas no primeiro caracter e no ultimo.</li>
            </ul>
        </div>
        <form className='w-full my-6' autoComplete='off'  onSubmit={handleSubmit((data) => {console.log(data);submitForm(data)})}>
            
            <div className="relative z-0 w-full mb-6 group">
              <input type="password" onKeyUp={(e:any) => {setPass(e.target.value)}} {...register('passwordStep')} className="block pt-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0  border-[#ccc] appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="" />
              <label htmlFor="passwordStep" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Senha</label>
              <PasswordStrengthBar scoreWords={['muito fraca',]} onChangeScore={(e:any) => setScore(e)} minLength={12} password={password} />
              {errors.passwordStep && <p className="text-red-500 text-xs italic">{errors.passwordStep.message?.toString()}</p>}
            </div>
            <div className={`${(password.length > 0 && score > 3 && rules.every(element => element === true)) ? 'flex' : 'hidden'} relative flex-col  gap-2 transition-all z-0 w-full mb-6 group`}>
                <input type="password" {...register('passwordConfirmation')} className="block border-b pt-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0  border-[#ccc] appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="" />
                <label htmlFor="passwordConfirmation" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirme a senha</label>
                {errors.passwordConfirmation && <p className="text-red-500 text-xs italic">{errors.passwordConfirmation.message?.toString()}</p>}
            </div> 
            <button type="submit" className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full  px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800">
               Save
            </button>
        </form> 
    </>
  )
}
