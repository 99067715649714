import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./LocalStorage";
import { toast } from "react-toastify";
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';

const AuthContext = createContext<any>({});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['jwt','myUser']); // Use react-cookie hooks
  const [user,setUser] = useState('user');
  const navigate = useNavigate();
  const [intervalId, setIntervalId] = useState<any>(null); // Create state for intervalId
  const validateJwt = async () => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt && jwt.token !== undefined) {
      fetch(`${process.env.REACT_APP_API}/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        }
      }).then((response) => {
        console.log(response)
        if (response.status !== 200) {
          removeCookie("jwt", { path: '/',secure:true }); // Set the JWT to cookies
          navigate("/login", { replace: true });
          return toast.error("Login expirado, faça login novamente");          
        }
        response.json().then((data) => {
          setUser(data);
          setCookie("myUser", JSON.stringify(data), { path: '/',secure:true }); // Set the JWT to cookies
        })
      }).catch(() => {
          removeCookie("jwt", { path: '/',secure:true }); // Set the JWT to cookies
          navigate("/login", { replace: true });
          clearInterval(intervalId); // Clear interval
          return toast.error("Login expirado, faça login novamente");          
      })
    }
  }

  useEffect(() => {
    validateJwt().then(() => {
        const x = setInterval(validateJwt, 1000 * 60 * 5)
        setIntervalId(x);
    });
  }, []);


    // call this function when you want to authenticate the user
    const checkEmail = async (data: any) => {
      const body =  await fetch(`${process.env.REACT_APP_API}/oauth2/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
      })
      if (body.status !== 200 && body.status !== 201) {
        return toast.error("Email inválido ou não cadastrado");
      }
        const response = await body.json();
        return response;
    };

    const resetPassword = async (data: any) => {
      const body =  await fetch(`${process.env.REACT_APP_API}/oauth2/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
      })
      if (body.status !== 200 && body.status !== 201) {
        return toast.error("Email inválido ou não cadastrado");
      }
        const response = await body.json();
        return response;
    };

    const checkPassword = async (data: any) => {
      const body =  await fetch(`${process.env.REACT_APP_API}/oauth2/password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
      })
      if (body.status !== 200 && body.status !== 201) {
        return toast.error("Senha inválida");
      }
        const response = await body.json();
        return response;
    };


  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    const body =  await fetch(`${process.env.REACT_APP_API}/oauth2/validate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    })
    if (body.status !== 200 && body.status !== 201) {
      return toast.error("Codigo inválido");
    }
      const response = await body.json();
      await setCookie('jwt', JSON.stringify(response), { path: '/',secure:true }); // Set the JWT to cookies
      navigate("/", { replace: true });
  };

  // call this function to sign out logged in user
  const sair = async () => {
    removeCookie('jwt');
    removeCookie('myUser');
  };

  const value = useMemo(
    () => ({
      user,
      cookies,
      setCookie,
      removeCookie,
      login,
      sair,
      checkEmail,
      resetPassword,
      checkPassword
    }),
    [cookies]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};