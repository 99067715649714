import {MdOutlineKeyboardDoubleArrowRight} from 'react-icons/md'

interface breaditem{
    title:string,
    link:string
    class:string
}

export default function Breadcrumb({title,links}:{title:string,links:breaditem[]}) {


    return (
        <div className='w-full mt-6'>
            <h1 className="roboto text-[14px] uppercase font-bold">{title}</h1>
            <div className="flex flex-row gap-1 text-xs items-center">
                {links.map((item:breaditem) => {
                    return <>
                        <a className={item.class} href={item.link}>{item.title}</a>
                        {item !== links[links.length-1] && <MdOutlineKeyboardDoubleArrowRight className='text-gray-400' />}
                    </>
                })}
            </div>
        </div>
    )
}