import Cookies from "js-cookie";
import { toast } from "react-toastify";


export const validToken = async (uuid:string) => {
    const response = await fetch(`${process.env.REACT_APP_API}/segeam/start/${uuid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    })
    if (response.status !== 200) {
      toast.error("Convite inválido!");
      return ""
    }
    return response.json();
}


export const validTokenReset = async (uuid:string) => {
  const response = await fetch(`${process.env.REACT_APP_API}/oauth2/reset/${uuid}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  })
  if (response.status !== 200) {
    toast.error("Convite inválido!");
    return ""
  }
  return response.json();
}

export const resetSavePassword = async (uuid:string,password:string) => {
  const response = await fetch(`${process.env.REACT_APP_API}/oauth2/save-password/${uuid}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },body: JSON.stringify({password})
  })
  if (response.status !== 200) {
    toast.error("Token expirado, solicite a troca novamente!");
    return ""
  }
  return response.json();
}


export const savePassword = async (uuid:string,password:string) => {
  const response = await fetch(`${process.env.REACT_APP_API}/segeam/start/${uuid}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({password})
  })
  if (response.status !== 200) {
    toast.error("Erro ao salvar!");
    return ""
  }
  return response.json();
}

export const validate2FA = async (uuid:string,code:string) => {
  const response = await fetch(`${process.env.REACT_APP_API}/segeam/start/${uuid}/2fa`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({code})
  })
  if (response.status !== 200) {
    toast.error("Código incorreto!");
    return ""
  }
  return response.json();
}


export const getUsers = async (id?:string,type?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/employees/${id !== undefined ? id : ''}${type !== undefined ? `?type=${type}` : ''}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        }
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para acessar esta página!");
      }
      return response.json();
    }
  }

  export const createUser = async (data:any, id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/employees/${id !== undefined ? id : ''}`, {
        method: id !== undefined ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify(data)
      })
      if (response.status !== 200) {
        const json = await response.json();
        return toast.error(json.Error);
      }
      return toast.success("Usuário criado com sucesso!");
    }
  } 

  export const deleteUser = async (id:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/employees/${id !== undefined ? id : ''}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }     
  export const getReport = async (id?:string, data?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/unit/${id}/report/${data}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        }
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para acessar esta página!");
      }
      return response.json();
    }
  } 


  export const getUnits = async (id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/units/${id !== undefined ? id : ''}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        }
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para acessar esta página!");
      }
      return response.json();
    }
  } 

  export const createUnits = async (data:any, id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/units/${id !== undefined ? id : ''}`, {
        method: id !== undefined ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify(data)
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 

  export const deleteUnits = async (id:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/units/${id !== undefined ? id : ''}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }   

  export const getSector = async (unit:string, id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    console.log(`${process.env.REACT_APP_API}/unitsbysector/${unit !== undefined ? unit : ''}/sectors/${id !== undefined ? id : ''}`)
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/units/${unit !== undefined ? unit : ''}/sectors/${id !== undefined ? id : ''}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 


  export const createSector = async (data:any, unit:string, id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const url = id !== undefined ? `${process.env.REACT_APP_API}/unitsbysectorbyid/${unit}/sectors/${id}` : `${process.env.REACT_APP_API}/unitsbysector/${unit}/sectors`;
      const response = await fetch(url, {
        method: id !== undefined ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify(data)
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 
  
  export const deleteSetor = async (unit:string,id:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const url = id !== undefined ? `${process.env.REACT_APP_API}/unitsbysectorbyid/${unit}/sectors/${id}` : `${process.env.REACT_APP_API}/unitsbysector/${unit}/sectors`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }   
  export const getPatients = async (id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/unit/${id !== undefined ? id : ''}/patient`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 

  export const getBeds = async (sector:string, id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const url = id !== undefined ? `${process.env.REACT_APP_API}/sectorByBeds/${sector}/beds/${id}` : `${process.env.REACT_APP_API}/sector/${sector}/beds`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 


  export const createLeito = async (data:any, setor:string, id?:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/sector/${setor}/beds/${id !== undefined ? id : ''}`, {
        method: id !== undefined ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify(data)
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 

  export const deleteLeito = async (setores:string,id:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/sector/${setores}/beds/${id !== undefined ? id : ''}`, {
      method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }

  export const removePaciente = async (bed:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/bed/${bed}/patient/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }


  export const addToLeito = async (data:any, unit:string, id?: string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/bed/${unit}/patient/?addToLeito=true`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify({...data,unit:unit})
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 

  export const createPatient = async (data:any, unit:string, id?: string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/bed/${unit}/patient/${id !== undefined ? id : ''}`, {
        method: id !== undefined ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify({...data,unit:unit})
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 


  export const createFugulin = async (data:any, bed:string, id?: string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/bed/${bed}/patient/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify(data)
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }
  

  export const editFugulin = async (data:any, bed:string, id?: string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/fugulins/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify(data)
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }

  export const deleteFugulin = async (id:string) => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/fugulins/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
        body: JSON.stringify({uuid:id})
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  }
  
  export const getFugulins = async () => {
    const jwt = JSON.parse(Cookies.get('jwt') || "{}"); // Get the JWT from cookies
    if (jwt) {
      const response = await fetch(`${process.env.REACT_APP_API}/fugulins`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${jwt.token}`
        },
      })
      if (response.status !== 200) {
        return toast.error("Sem permissão para conseguir esta página!");
      }
      return response.json();
    }
  } 