export default function Success(){

    return (
        <>
       <div className='flex flex-col justify-center items-center'>
            <h1 className='font-semibold text-gray-800 text-3xl mb-2 text-center'>Sucesso!</h1>
            <h2 className='text-sm text-gray-400'>Sua conta está ativa e pronta para uso.</h2>
            
            <p className='text-sm mt-24 text-gray-400'>Você pode fazer o login no sistema</p>
            <a href='/login' className='mt-4 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded shadow-lg'>Ir para Login</a>

        </div>
    </>
    )

}